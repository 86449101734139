/* istanbul ignore file */
import { Modal } from 'antd'
export default class Notification {
  error(data) {
    let { message, description = '' } = data
    return Modal.error({
      title: message,
      content: (
        <>
          {description}
        </>
      ),
      okText: 'Close',
      centered: true,
      okButtonProps: {
      },
      onOk() {
        if (data.onCancel) {
          data.onCancel()
        }
      },
      onCancel() {
        if (data.onCancel) {
          data.onCancel()
        }
      }
    })
  }

  success(data) {
    let { message, description = '' } = data
    return Modal.success({
      title: message,
      content: (
        <>
          {description}
        </>
      ),
      okText: 'Close',
      centered: true,
      okButtonProps: {
      },
      onOk() {
        if (data.onOk) {
          data.onOk()
        }
      },
      onCancel() {
        if (data.onCancel) {
          data.onCancel()
        }
      }
    })
  }

  warning(data) {
    let { message, description = '' } = data
    return Modal.warning({
      title: message,
      content: (
        <>
          {description}
        </>
      ),
      okText: 'Close',
      centered: true,
      okButtonProps: {
      },
      onOk() {
        if (data.onCancel) {
          data.onCancel()
        }
      },
      onCancel() {
        if (data.onCancel) {
          data.onCancel()
        }
      }
    })
  }

  confirm(data) {
    let {
      message,
      description = '',
      okButtonProps,
      cancelButtonProps,
      footer
    } = data
    return Modal.confirm({
      title: message,
      content: (
        <>
          {description}
        </>
      ),
      okText: 'Call',
      centered: true,
      okButtonProps: okButtonProps,
      onOk() {
        if (data.onOk) {
          data.onOk()
        }
      },
      cancelButtonProps: cancelButtonProps,
      onCancel() {
        if (data.onCancel) {
          data.onCancel()
        }
      },
      footer: footer
    })
  }
}