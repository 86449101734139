/* istanbul ignore file */
import { config } from '../config/index'
import axios from '../utils/axios';
import { getStorage } from '../hooks/useCookieStorage';

export const baseUrl = config.api;
export const _headers = {
    Accept: 'application/form-data',
    'Content-Type': 'application/json',
    "authorization" : getStorage("token") ? "Bearer " + getStorage("token") : ""
};

export function get({url, params , headers = {}}) {
    return axios({
        url,
        method: 'get',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
    }).catch(err => {
        if(err?.response?.status == "401"){
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = "/login";
            return
        }
        throw err;
    })
}

export function post({url, params, data , headers = {}, responseType}) {
    return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
        data,
        responseType,
    }).catch(err => {
        if(err?.response?.status == "401"){
            if(url == "/auth/login"){
                throw err;
            }
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = "/login";
            return
        }
        throw err;
    })
}

export function put({url, params, data , headers = {}}) {
    return axios({
        url,
        method: 'put',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
        data,
    }).catch(err => {
        if(err?.response?.status == "401"){
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = "/login";
            return
        }
        throw err;
    })
}

export function deleteApi({url, params, data , headers = {}}) {
    return axios({
        url,
        method: 'delete',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
        data,
    }).catch(err => {
        if(err?.response?.status == "401"){
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = "/login";
            return
        }
        throw err;
    })
}

export function postForm({url, params, data , headers = {}, responseType}) {
    const { "Content-Type": _, ...head } = { ..._headers, ...headers };
      return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: head,
        params,
        data,
        responseType,
    }).catch(err => {
          if(err?.response?.status == "401"){
              document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
              window.location.href = "/login";
              return
          }
          throw err;
      })
}

