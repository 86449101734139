import React, { useEffect, useState, useRef } from "react";
import Layout from "../component/layout/Layout";
import { Helmet } from "react-helmet";
import { itemRender } from '../utils/common'
import { useNavigate } from 'react-router'
import Notification from "../services/Notification";
import { HomeOutlined, EyeFilled, EditFilled, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Input, Breadcrumb, Row, Col, Table, Pagination, Collapse} from "antd";
import SkeletonLoading from '../component/SkeletonLoading';
import PaginationShowingCount from '../component/table/PaginationShowingCount';
import PaginationChangeLimit from '../component/table/PaginationChangeLimit';
import TableLoading from '../component/table/Loading';
import { getCustomer } from '../services/settingDocument'
import dayjs from "dayjs";
import { ReactComponent as Arrow } from '../assets/arrow.svg'
const { Panel } = Collapse;

const itemMenu = [
  {
    href: `/setting-document`,
    title: (
      <>
        <span><HomeOutlined style={{marginRight:"10px", color:"#898787"}}/>Setting Document</span>
      </>
    )
  },
]

const App = () => {
  const navigate = useNavigate()
  const initialized = useRef(false);
  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [searchValue, setSearchValue] = useState("");
  const [dataList, setDataList] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [sortBy, setSortBy] = useState("updateAt|desc");
  const [tableKey, setTableKey] = useState(0);

  const Noti = new Notification()

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setLoadingFirst(true);
      fnGetList();
    }
  },[]);

  const Columns = [
    {
      title: 'No.',
      dataIndex: 'seqNo',
      align: 'center',
      key: 'seqNo',
      sorter: false,
      width: 50,
      render: (text) => <div className="font16 px-text-center">{text || "-"}</div>
    },
    {
      title: 'Customer ID',
      dataIndex: 'customerCode',
      key: 'customerCode',
      align: 'left',
      sorter: true,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      align: 'left',
      sorter: true,
      render: (text) => <span className="font16">{text || "-"}</span>
    },
    {
      title: 'Updated At',
      dataIndex: 'updateAt',
      key: 'updateAt',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (data) => <span className="font16">{data ? dayjs(data).format('DD/MM/YYYY HH:mm:ss') : "-"}</span>
    },
  ];

  let dataSource = dataList.map((r, i) => {
    return {
      seqNo: limit === 'All' ? i + 1 : i + 1 + ((page - 1) * limit),
      key: i,
      ...r
    }
  });

  const onLimitChange = (e) => {
    setPage(1);
    setLimit(e);
    fnGetList(1, e);
  }

  const onPageChange = (e) => {
    setPage(e);
    fnGetList(e, limit);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    const newSortBy = sortField ? `${sortField}|${sortOrder}` : sortBy;
    setSortBy(newSortBy);
    fnGetList(page, limit, false, newSortBy);
  }

  const fnGetList = async (page = 1, limit = 10, isSearch, s) => {
    try {
      let body = {
        offset: limit === "All" ? '0' : (page - 1) * limit,
        limit: limit === 'All' ? "-1" : limit,
        sortBy: s ? s : sortBy
      }
      if (isSearch)
        body.search = searchValue;
      setPage(page);
      setLimit(limit);
      let res = await getCustomer(body);
      if (res?.data?.resultCode == "20000") {
        setDataList(res.data.resultData);
        setCount(res.data.rowCount);
      } else {
        // Noti.error({
        //   message: 'Error',
        //   description: res.resultDescription || ''
        // })
      }
      setLoadingFirst(false);
    } catch (error) {
      Noti.error({
        message: 'Error',
        description: error.response?.data?.resultDescription || ''
      });
      setDataList([]);
      setCount(0);
      setLoadingFirst(false);
    }
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const fnClear = () => {
    setSearchValue("");
    setPage(1);
    setLimit("10");
    setTableKey(prevKey => prevKey + 1);
    setSortBy("updateAt|desc");
    fnGetList(1, 10, false, "updateAt|desc");
  }

  return <>
    <Layout activeKey="setting-document">
      <Helmet>
        <title>Document Manager</title>
      </Helmet>
      <Breadcrumb itemRender={itemRender} items={itemMenu} />
      {loadingFirst ? <SkeletonLoading /> : <>
        <div style={{padding:"20px"}}>
          <div className="header-action">
            <h2 style={{ textAlign: 'left',color:"#646965" }}>Setting Document</h2>
            <Button className="blue-btn" icon={<PlusOutlined />} style={{height:"40px", width:"200px"}} onClick={()=> navigate(`/setting-document/add`)} disabled={loadingApi}>Create Customer</Button>
          </div>
          <Card className='card-list doc collapse' bordered={false} style={{marginBottom:"30px"}}
            title={
              <div onClick={toggleExpand} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor:"pointer", padding:"15px 24px" }}>
                <span style={{fontWeight:"400"}}><FilterFilled style={{color:"#63A8C3", marginRight:"10px"}}/>Filter</span>
                {expanded ?  
                  <Arrow height={20} width={20} style={{transform:"rotate(90deg)", color:"#63A8C3"}}/> : 
                  <Arrow height={20} width={20} style={{transform:"rotate(-90deg)", color:"#63A8C3"}}/> 
                }
              </div>
            }
          >
            <Collapse activeKey={expanded ? '1' : null} ghost>
              <Panel key="1" showArrow={false}>
                <div style={{padding:"20px 0 10px 50px"}}>
                  <span style={{marginRight:"20px"}}>Customer Name / ID :</span>
                  <Input className="input-upload" style={{marginBottom:"10px"}} value={searchValue} 
                    onChange={(e) => setSearchValue(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter')fnGetList(1, limit, true)}}
                  />
                </div>
                <div style={{borderTop:"1px solid #C8C8CD", width:"100%", textAlign:"right", padding:"20px"}}>
                  <Button className="blue-btn" style={{height:"40px", width:"100px", marginRight:"20px"}} onClick={() => fnGetList(1, limit, true)} disabled={loadingApi}>Search</Button>
                  <Button className="cancel-btn" style={{height:"40px", width:"100px"}} onClick={() => fnClear()} disabled={loadingApi}>Clear</Button>
                </div>
              </Panel>
            </Collapse>
          </Card>
          <Card className='card-list doc' bordered={false}>
            <div>
              {loadingApi ? <SkeletonLoading /> : <>
                {/* {dataList.length > 0 ? <> */}
                  <Table
                    className="setting-table"
                    key={tableKey}
                    rowKey={record => record.key}
                    bordered
                    showSorterTooltip={false}
                    scroll={{ x: 300 }}
                    columns={[
                      ...Columns,
                      {
                        title: 'Action',
                        dataIndex: '',
                        align: 'center',
                        key: '',
                        sorter: false,
                        width: 200,
                        render: (data) => <div>
                          <Tooltip title="View" overlayClassName="tooltip-icon">
                            <EyeFilled className="icon-action" style={{marginRight:"10px"}} onClick={()=>navigate(`/setting-document/view/${data.customerCode}`)}/>
                          </Tooltip>
                          <Tooltip title="Edit" overlayClassName="tooltip-icon" onClick={()=>navigate(`/setting-document/edit/${data.customerCode}`)}>
                            <EditFilled className="icon-action" />
                          </Tooltip>
                        </div>
                      }
                    ]}
                    loading={{
                      spinning: loadingApi,
                      indicator: <TableLoading />
                    }}
                    locale={{ emptyText: <div> No Data </div> }}
                    pagination={false}
                    dataSource={dataSource}
                    size="small"
                    sortDirections={["ascend","descend","ascend"]}
                    footer={() => <>
                      <Row gutter={16}>
                        <Col className="" span={12}>
                          <PaginationChangeLimit onChange={(e) => {
                            onLimitChange(e);
                          }} limit={limit} loading={loadingApi} />
                          <PaginationShowingCount limit={limit} count={count} page={page} />
                        </Col>
                        <Col className="text-right" span={12} style={{ textAlign: 'right' }}>
                          {
                            limit === 'All' ? <></> :
                              <Pagination
                                simple
                                current={page}
                                total={count}
                                pageSize={limit}
                                loading={loadingApi}
                                onChange={(page, pageSize) => {
                                  onPageChange(page);
                                }} />
                          }
                        </Col>
                      </Row>
                    </>}
                    onChange={handleTableChange}
                  />
                {/* </> : <>
                  <div className="no-data">No Data Found</div>
                </>} */}
              </>}
            </div>
          </Card>
        </div>
      </>}
    </Layout>
  </>;
};

export default App;