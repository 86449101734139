import React, { useState } from 'react';
import { Button, Form, Card } from 'antd';
import Input from '../component/FloatInput/index'
import { useAuth } from "../hooks/useAuth";
import { postLogin } from "../services/generateDocument";
import Notification from "../services/Notification";
import { useAuthStore } from "../services/login";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const App = () => {
  const { login } = useAuth();
  const [loadingApi, setLoadingApi] = useState(false);
  const Noti = new Notification()
  const { setToken } = useAuthStore()

  const onFinish = (values) => {
    setLoadingApi(true)
    postLogin(values).then((res) => {
      let resData = res.data
      setLoadingApi(false)

      if (resData.resultCode === "20000") {
        // window.localStorage.setItem('user', JSON.stringify(resData.cookie));
        login(resData.resultData.userToken)
        setToken(resData.resultData.userToken)
        window.location.href = "/generate-document";
      }else {
        Noti.error({
          message: 'Error',
          description:resData.resultDescription
        })
      }
    }).catch((error) => {
      setLoadingApi(false)
      if(error?.response?.status == "401") {
        Noti.error({
          message: 'Error',
          description: "Incorrect Username or Password"
        })
        return
      }
      Noti.error({
        message: 'Error',
        description:error.message || ''
      })
    });
  };

  return (
    <div className="login-container">
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
      <Card style={{ width: 500, border: "none", margin: "0 20px", boxShadow: "0 0 10px 5px rgba(0,0,0,0.2)", borderRadius:"60px"}}>
      <div style={{ display: "flex", justifyContent: "center", padding:'30px 0px', marginTop:"10px" }}>
        <UserOutlined className='logo-login'/>
        <div level={2} style={{textAlign:"center"}}>
          <div style={{fontSize:50, fontWeight: 900, fontWeight: 900, color: "#2786b3"}}>เข้าสู่ระบบ</div>
        </div>
      </div>
      <Form
        name="login"
        layout="vertical"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          // label="Email"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username',
            },
          ]}
        >
          <Input label='ชื่อผู้ใช้' prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
          style={{margin:"20px 0"}}
        >
          <Input type="password" label='รหัสผ่าน' prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            // offset: 8,
            span: 24,
          }}
          style={{textAlign:"center"}}
        >
          <Button data-testid="summit" type="primary" htmlType="submit" className='login-btn' disabled={loadingApi}>
            เข้าสู่ระบบ
            <span style={{marginLeft: '30px', marginRight: '20px', fontWeight: 'bold'}}>&gt;</span>
          </Button>
        </Form.Item>
      </Form>
      </Card>
      </div>
    </div>
  );
}
export default App;
